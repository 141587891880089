<template>
  <svg
    width="84"
    height="83"
    viewBox="0 0 84 83"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="42" cy="41.5" r="41.5" :fill="color" />
    <path
      d="M59 29L35.625 52L25 41.5455"
      stroke="white"
      stroke-width="6"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: '#E58413',
    },
  },
};
</script>

<style lang="scss" scoped></style>
