var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('section',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.$t('enduser.signUp.verifyAccount'))+" ")]),_c('section',{staticClass:"content"},[_c('div',{staticClass:"info"},[_vm._v(" "+_vm._s(_vm.$t('enduser.signUp.pleaseEnterYourPhoneNumber'))+" ")]),_c('div',{staticClass:"inputs"},[_c('SelectElement',{attrs:{"value":_vm.$store.getters.userVerifyPhone.callingCode,"options":_vm.$store.getters.countriesVerifyPhoneOptions,"placeholder":_vm.$t('serviceProvider.settingsCompany.country'),"key-name":"label","search":"","filter-options":{
          getFrom: 'extended',
          filteringProperties: ['name', 'callingCode'],
        }},on:{"input":function($event){return _vm.$store.commit('setUserVerifyPhoneCountry', $event)}},scopedSlots:_vm._u([{key:"selected-label",fn:function(ref){
        var extended = ref.extended;
return [_c('span',{staticClass:"list-item-flag"},[(extended)?_c('img',{attrs:{"src":extended.flag,"alt":"flag","width":"24","height":"24"}}):_vm._e()])]}},{key:"list-label",fn:function(ref){
        var extended = ref.extended;
return [_c('div',{staticClass:"list-style"},[_c('span',{staticClass:"list-item-flag"},[_c('img',{attrs:{"src":extended.flag,"alt":"flag","width":"24","height":"24"}})]),_c('span',{staticClass:"list-item-text"},[_c('span',{staticClass:"country-name"},[_vm._v(" "+_vm._s(extended.name)+" ")]),_c('span',{staticClass:"country-calling-code"},[_vm._v(" "+_vm._s(extended.callingCode)+" ")])])])]}}])}),_c('div',{staticClass:"phone-input"},[_c('InputElement',{attrs:{"value":_vm.$store.getters.userVerifyPhone.phoneNumber},on:{"input":function($event){return _vm.$store.commit('setUserVerifyPhoneNumber', $event)}}}),_c('div',{staticClass:"calling-code"},[_vm._v(" "+_vm._s(_vm.$store.getters.userVerifyPhone.callingCode)+" ")])],1)],1),(_vm.$store.getters.errorResponse.action == 'verifyPhoneSendCode')?_c('ResponseAlert',{staticClass:"mt-3",attrs:{"response":_vm.$store.getters.errorResponse.data}}):_vm._e(),_c('div',{staticClass:"action mt-3"},[_c('ButtonElement',{attrs:{"loading":_vm.$store.getters.loading,"disabled":_vm.$store.getters.userVerifyPhoneFullNumber.length < 10,"orange":""},on:{"click":_vm.handleSubmit}},[_vm._v(" "+_vm._s(_vm.$t('enduser.signUp.sendCode'))+" ")])],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }