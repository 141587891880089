<template>
  <div>
    <section class="title">
      {{ $t('enduser.signUp.verifyAccount') }}
    </section>

    <section class="content">
      <div class="info">
        {{ $t('enduser.signUp.pleaseEnterYourPhoneNumber') }}
      </div>

      <div class="inputs">
        <SelectElement
          :value="$store.getters.userVerifyPhone.callingCode"
          @input="$store.commit('setUserVerifyPhoneCountry', $event)"
          :options="$store.getters.countriesVerifyPhoneOptions"
          :placeholder="$t('serviceProvider.settingsCompany.country')"
          key-name="label"
          search
          :filter-options="{
            getFrom: 'extended',
            filteringProperties: ['name', 'callingCode'],
          }"
        >
          <template #selected-label="{ extended }">
            <span class="list-item-flag">
              <img
                v-if="extended"
                :src="extended.flag"
                alt="flag"
                width="24"
                height="24"
              />
            </span>
          </template>
          <template #list-label="{ extended }">
            <div class="list-style">
              <span class="list-item-flag">
                <img :src="extended.flag" alt="flag" width="24" height="24" />
              </span>
              <span class="list-item-text">
                <span class="country-name">
                  {{ extended.name }}
                </span>
                <span class="country-calling-code">
                  {{ extended.callingCode }}
                </span>
              </span>
            </div>
          </template>
        </SelectElement>
        <div class="phone-input">
          <InputElement
            :value="$store.getters.userVerifyPhone.phoneNumber"
            @input="$store.commit('setUserVerifyPhoneNumber', $event)"
          />
          <div class="calling-code">
            {{ $store.getters.userVerifyPhone.callingCode }}
          </div>
        </div>
      </div>

      <ResponseAlert
        v-if="$store.getters.errorResponse.action == 'verifyPhoneSendCode'"
        :response="$store.getters.errorResponse.data"
        class="mt-3"
      ></ResponseAlert>

      <div class="action mt-3">
        <ButtonElement
          @click="handleSubmit"
          :loading="$store.getters.loading"
          :disabled="$store.getters.userVerifyPhoneFullNumber.length < 10"
          orange
        >
          {{ $t('enduser.signUp.sendCode') }}
        </ButtonElement>
      </div>
    </section>
  </div>
</template>

<script>
import SelectElement from '../FormControl/SelectElement/SelectElement.vue';
import InputElement from '../FormControl/InputElement.vue';
import ButtonElement from '../FormControl/ButtonElement.vue';
import store from '../../store';

export default {
  setup(props, { emit }) {
    const handleSubmit = async () => {
      await store.dispatch('verifyPhoneSendCode', {
        phoneNumber: store.getters.userVerifyPhoneFullNumber,
      });
      emit('next-step');
    };

    return {
      handleSubmit,
    };
  },
  components: {
    ButtonElement,
    InputElement,
    SelectElement,
  },
};
</script>

<style lang="scss" scoped>
.title {
  color: $hty-orange;
  font-style: normal;
  font-weight: 500;
  font-size: 2rem;
  line-height: 1.2;
  display: flex;
  justify-content: center;
}
.info {
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.2;
  color: $hty-dark-grey;
  margin-top: $hty-margin-low;
}
.inputs {
  display: flex;
  justify-content: space-between;
  margin-top: $hty-margin-low;

  > :first-child {
    margin-right: $hty-margin-low;
    width: 120px;

    ::v-deep .select-dropdown-container {
      width: 380px;
    }
  }
  > :last-child {
    width: 100%;

    ::v-deep .input {
      padding-left: 60px;
    }
  }
}
.action {
  > :first-child {
    width: 100%;
  }
}
.phone-input {
  position: relative;
}
.calling-code {
  position: absolute;
  bottom: 12px;
  left: 10px;
}
.list-style {
  display: flex;
  align-items: center;

  > :first-child {
    margin-right: 10px;
  }
}
.list-item-flag {
  position: relative;
  top: 2px;
}
</style>
