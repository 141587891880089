<template>
  <div>
    <section class="title">
      {{ $t('enduser.signUp.verifyAccount') }}
    </section>
    <ValidationObserver ref="verifyCodeValidationRef">
      <section class="content">
        <div class="info">
          <span>{{ $t('enduser.signUp.pleaseEnterTheCodeSent') }}</span>
          <span>{{ $store.getters.userVerifyPhoneFullNumber }}</span>
        </div>
        <div class="inputs">
          <InputElement
            v-model="code"
            :placeholder="$t('enduser.signUp.enterCode')"
            validation-name="code"
          />
        </div>
        <div class="sub-action">
          <span class="text">
            {{ $t('enduser.signUp.didntReceiveCode') }}
          </span>
          <ButtonElement @click="handleResendCode" link>
            {{ $t('enduser.signUp.requestAgain') }}
          </ButtonElement>
        </div>
        <div class="action">
          <ButtonElement
            @click="handleSubmit"
            orange
            :loading="$store.getters.loading"
          >
            {{ $t('enduser.signUp.verify') }}
          </ButtonElement>
        </div>
      </section>
    </ValidationObserver>
  </div>
</template>

<script>
import InputElement from '../FormControl/InputElement.vue';
import ButtonElement from '../FormControl/ButtonElement.vue';
import { ref } from '@vue/composition-api';
import store from '../../store';

export default {
  setup(_, { emit }) {
    const code = ref('');
    const verifyCodeValidationRef = ref(null);

    const handleSubmit = async () => {
      await store.dispatch('verifyPhoneConfirmCode', {
        payload: {
          phoneNumber: store.getters.userVerifyPhoneFullNumber,
          verificationCode: code.value,
        },
        validator: verifyCodeValidationRef.value,
      });
      emit('next-step');
    };

    const handleResendCode = async () => {
      await store.dispatch('verifyPhoneSendCode', {
        phoneNumber: store.getters.userVerifyPhoneFullNumber,
      });
    };

    return {
      code,
      handleSubmit,
      handleResendCode,
      verifyCodeValidationRef,
    };
  },
  components: {
    ButtonElement,
    InputElement,
  },
};
</script>

<style lang="scss" scoped>
.title {
  color: $hty-orange;
  font-style: normal;
  font-weight: 500;
  font-size: 2rem;
  line-height: 1.2;
  display: flex;
  justify-content: center;
}
.info {
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.2;
  color: $hty-dark-grey;
  margin-top: $hty-margin-low;

  > :last-child {
    margin-left: 5px;
    color: $hty-black;
  }
}
.inputs {
  margin-top: $hty-margin-low;
}
.action {
  margin-top: $hty-margin-hi;
  > :first-child {
    width: 100%;
  }
}
.sub-action {
  margin-top: $hty-margin-low;

  > * {
    font-weight: 500;
    font-size: 0.9rem;
    line-height: 1.1;
  }
}
.text {
  color: $hty-black;
  margin-right: 5px;
}
</style>
