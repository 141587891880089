<template>
  <div class="verified">
    <section class="checkmark">
      <CheckmarkCircleIcon />
    </section>
    <section class="subtitle">
      {{ $t('enduser.signUp.yourAccountHasBeenVerified') }}
    </section>
    <section class="title">
      {{ $t('enduser.signUp.verificationIsSuccessful') }}
    </section>
    <section class="action">
      <ButtonElement :anchor="{ name: 'Dashboard' }" orange>
        {{ $t('enduser.signUp.goToDashboard') }}
      </ButtonElement>
    </section>
  </div>
</template>

<script>
import ButtonElement from '../FormControl/ButtonElement.vue';
import CheckmarkCircleIcon from '../Svgs/CheckmarkCircleIcon.vue';

export default {
  components: {
    CheckmarkCircleIcon,
    ButtonElement,
  },
};
</script>

<style lang="scss" scoped>
.verified {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.subtitle {
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.2;
  color: $hty-dark-grey;
  margin-top: 3rem;
}

.title {
  font-weight: bold;
  font-size: 1.5rem;
  line-height: 1.2;
  color: $hty-orange;
  margin-top: 2.5rem;
}
.action {
  margin-top: 3rem;
}
</style>
