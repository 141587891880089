<template>
  <div class="verify-account">
    <header class="header">
      <LogoIcon color="white" />
    </header>
    <main class="verify-box">
      <section class="back">
        <ButtonElement @click="handleBack" link>
          <ArrowIcon />
        </ButtonElement>
      </section>
      <section class="steps">
        <VerifyAccountFirstStep
          v-if="currentStep === 1"
          @next-step="currentStep++"
        />
        <VerifyAccountSecondStep
          v-else-if="currentStep === 2"
          @next-step="currentStep++"
        />
        <VerifyAccountThirdStep v-else-if="currentStep === 3" />
      </section>
    </main>
  </div>
</template>

<script>
import { onMounted, ref } from '@vue/composition-api';
import LogoIcon from '../../../components/Svgs/LogoIcon.vue';
import ButtonElement from '../../../components/FormControl/ButtonElement.vue';
import ArrowIcon from '../../../components/Svgs/ArrowIcon.vue';
import store from '../../../store';
import router from '../../../router';
import VerifyAccountFirstStep from '../../../components/VerifyAccount/VerifyAccountFirstStep.vue';
import VerifyAccountSecondStep from '../../../components/VerifyAccount/VerifyAccountSecondStep.vue';
import VerifyAccountThirdStep from '../../../components/VerifyAccount/VerifyAccountThirdStep.vue';

export default {
  setup() {
    onMounted(async () => {
      store.dispatch('fetchCountries');
    });
    const currentStep = ref(1);
    const handleBack = () => {
      if (currentStep.value > 1) currentStep.value--;
      else router.push({ name: 'Dashboard' });
    };

    return {
      currentStep,
      handleBack,
    };
  },
  components: {
    VerifyAccountThirdStep,
    VerifyAccountSecondStep,
    VerifyAccountFirstStep,
    ArrowIcon,
    ButtonElement,
    LogoIcon,
  },
};
</script>

<style lang="scss" scoped>
.verify-account {
  padding: 20px;
  background-position: center;
  background-size: cover;
  height: 100vh;
  background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    url('../../../assets/pics/pexels-fauxels-3184634.png');
}
.verify-box {
  background-color: white;
  border-radius: 10px;
  width: 460px;
  margin: 0 auto;
  padding: 32px 40px;
}
.title {
  color: $hty-orange;
  font-style: normal;
  font-weight: 500;
  font-size: 2rem;
  line-height: 1.2;
  display: flex;
  justify-content: center;
}
// .info {
//   font-weight: normal;
//   font-size: 1rem;
//   line-height: 1.2;
//   color: $hty-dark-grey;
//   margin-top: $hty-margin-low;
// }
// .inputs {
//   display: flex;
//   justify-content: space-between;
//   margin-top: $hty-margin-low;

//   > :first-child {
//     margin-right: $hty-margin-low;
//     width: 120px;

//     ::v-deep .select-dropdown-container {
//       width: 380px;
//     }
//   }
//   > :last-child {
//     width: 100%;

//     ::v-deep .input {
//       padding-left: 60px;
//     }
//   }
// }
// .action {
//   margin-top: $hty-margin-hi;
//   > :first-child {
//     width: 100%;
//   }
// }
// .phone-input {
//   position: relative;
// }
// .calling-code {
//   position: absolute;
//   bottom: 16px;
//   left: 10px;
// }
// .list-style {
//   > :first-child {
//     margin-right: 10px;
//   }
// }
</style>
